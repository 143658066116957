import { ANIMAL_TYPE } from '../constants';
import { CatGenderProps } from '../types';

export const sortCatData = <T>(
  data: T[],
  keyToSort: keyof T,
  direction: 'ascending' | 'descending' | 'none',
) => {
  if (direction === 'none') {
    return data
  }
  const compare = (objectA: T, objectB: T) => {
    const valueA = objectA[keyToSort]
    const valueB = objectB[keyToSort]

    if (valueA === valueB) {
      return 0
    }

    if (valueA > valueB) {
      return direction === 'ascending' ? 1 : -1
    } else {
      return direction === 'ascending' ? -1 : 1
    }
  }

  return data.slice().sort(compare)
}
export const filterCatsByGender = (type: string, data: catProps[]) => {
  let catData: CatGenderProps[] = [];
  const filteredData = data.filter(m => m.gender === type);
  filteredData.map(item => item?.pets?.filter((p: PetProps) => {
    if (p.type === ANIMAL_TYPE){
      catData = [...catData,  {...p, genderType: type, parent:item}]
    }
    return catData;
  }));
 return sortCatData(catData, "name", "ascending");
}