import styled from 'styled-components';

export const H2 = styled.h2`
color: '#63666A';
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
`;

export const maleCollapseStyle: React.CSSProperties = {
  backgroundColor: '#DBF3FA',
};

export const femaleCollapseStyle: React.CSSProperties = {
  backgroundColor: '#FFC0CB',
};

export const rightSpan: React.CSSProperties = {
  float: 'right'
}

export const renderDiv: React.CSSProperties = {
  width: '100%'
}