import React, { useCallback, useContext } from "react";
import { Button, List } from "antd";
import { GenderContext } from "../context/genderContext";
import { CatGenderProps, GenderContextType } from "../types";
import { PetModal } from "./PetModal";
import { renderDiv, rightSpan } from '../styles'
import { MALE } from "../constants";

const RenderItem = ({ selectedItem, isModalOpen, handleOk, handleCancel, item, showModal }: ModalProps): React.ReactElement | JSX.Element => <List.Item>
  <div style={renderDiv}>
    <div>
      <List.Item.Meta
        key={item?.name}
        title={<>{item?.name} <span style={rightSpan}><Button type="primary" onClick={useCallback((() => showModal(item)), [])}>
          View details
        </Button></span>
        </>}
      />
    </div>
  </div>
  <PetModal {...{ selectedItem, isModalOpen, handleOk, handleCancel, item }} />
</List.Item>
export const ListCatsByGender = ({ genderType }: GenderSectionProps): React.ReactElement | JSX.Element => {
  const { maleCats, femaleCats, loading, isModalOpen, setIsModalOpen, selectedItem, setSelectedItem } = useContext(GenderContext) as GenderContextType;
  const data: CatGenderProps[] = (genderType === MALE) ? maleCats : femaleCats;
  const showModal = (item: CatGenderProps) => {
    setIsModalOpen(true)
    setSelectedItem(item)
  };
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  return <List
    data-testid="gender-lists"
    size="large"
    itemLayout="horizontal"
    loading={loading}
    dataSource={data}
    renderItem={(item: CatGenderProps) => {
      return <RenderItem {...{ selectedItem, isModalOpen, handleOk, handleCancel, item, showModal }} />
    }}
  />
}