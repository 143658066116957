import * as React from 'react';
import { useEffect, useState  } from 'react';
import { fetchPetUsers } from '../services';
import { filterCatsByGender } from '../utilities';
import { FEMALE, MALE } from '../constants';
import { CatGenderProps, GenderContextType } from '../types';

export const GenderContext = React.createContext<GenderContextType | null>(null);

export const GenderContextProvider:React.FC<BaseLayoutProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [petUsersData, setPetUsersData] = useState<catProps[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [maleCats, setMaleCats] = useState<PetProps[]>([]);
  const [femaleCats, setFemaleCats] = useState<PetProps[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<CatGenderProps>();
  
  useEffect(()=>{
    setLoading(true);
    fetchPetUsers(setPetUsersData, setError);
  },[])
  useEffect(()=>{
    const maleData = filterCatsByGender(MALE, petUsersData) ||  [];
    const femaleData = filterCatsByGender(FEMALE, petUsersData) ||  [];
    setMaleCats(maleData);
    setFemaleCats(femaleData);
    setLoading(false);
  },[petUsersData])
  return <GenderContext.Provider value={{ petUsersData, maleCats, femaleCats, loading, error, isModalOpen, setIsModalOpen,  selectedItem, setSelectedItem }}>{children}</GenderContext.Provider>;
}