import React, { useContext } from 'react';
import { Alert, Collapse } from 'antd';
import { ListCatsByGender } from './ListCatsByGender';
import { GenderContext } from '../context/genderContext';
import { GenderContextType } from '../types';
import { femaleCollapseStyle, maleCollapseStyle } from '../styles';


const GenderList = (): React.ReactElement | JSX.Element => {
    const { maleCats, femaleCats, error } = useContext(GenderContext) as GenderContextType;
    const items = [
    {
        key: '1',
        label: `Male Cats (${maleCats?.length || 0 })`,
        children: <ListCatsByGender  genderType={'Male'}/>,
        style: maleCollapseStyle
    },
    {
        key: '2',
        label: `Female Cats (${femaleCats?.length || 0 })`,
        children: <ListCatsByGender  genderType={'Female'}/>,
        style: femaleCollapseStyle
    }
    ];

  return <>
  {
    error?<Alert
    message="Error:"
    description="Oops unable to Load data, Please try after sometime!"
    type="error"
    closable
  />:<Collapse items={items} defaultActiveKey={['1']}/>
  }
  </>;
};

export default GenderList;