import { Dispatch } from "react";
export const fetchPetUsers= async(setPetUserData: Dispatch<catProps[]>, setError: Dispatch<boolean>)=> {
    const dataUrl = "https://gist.githubusercontent.com/medibank-digital/a1fc81a93200a7b9d5f8b7eae0fac6f8/raw/de10a4fcf717e6c431e88c965072c784808fd6b2/people.json";
    try {
        const res = await fetch(dataUrl);
        const peopleData = await res.json();
        setPetUserData(peopleData);
        setError(false);
    } catch (err) {
        setError(true);
    }
  }