import React from 'react';
import { Card, Col, Modal, Row, Typography } from "antd";

const { Text } = Typography;

export  const PetModal = ({selectedItem, isModalOpen, handleOk, handleCancel}: ModalProps) => <Modal mask={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
<Card data-testid='dialog-title' title={`Details about ${selectedItem?.name}`} bordered={false}>
<Row>
    <Col span={6}>
      <Text strong>Name:</Text>
    </Col>
    <Col span={6}>
    {selectedItem?.name}
    </Col>
  </Row>
  <Row>
    <Col span={6}>
    <Text strong>Gender: </Text>
    </Col>
    <Col span={6}>
    {selectedItem?.genderType}
    </Col>
  </Row>
  <Row>
    <Col span={6}>
    <Text strong>Owner Name: </Text>
    </Col>
    <Col span={6}>
    {selectedItem?.parent?.name}
    </Col>
  </Row>
  <Row>
    <Col span={6}>
    <Text strong>Owner Age: </Text>
    </Col>
    <Col span={6}>
    {selectedItem?.parent?.age}
    </Col>
  </Row>
  </Card>
</Modal>