import React from 'react';
import { GenderContextProvider } from '../context/genderContext';
import GenderList from './GenderList'
import { TITLE } from '../constants';
import {H2}  from '../styles';

const  App = () => <GenderContextProvider>
      <header>
        <H2 data-testid="title">
          {TITLE}
        </H2>
      </header>
      <main>
          <GenderList />
      </main>
</GenderContextProvider>

export default App;
